.social-icon {
    color: #94A6B8;
    size: 4rem;
}

.social-icon:hover {
    color: #367abf;
}

.appbarbox {
    opacity: 100;
}

@media (max-width: 767px) {
    .appbarbox {
        opacity: 0;
    }
}

html {
    scroll-behavior: smooth;
  }