.experience-card-wrapper {
    width: 500px ; /* Initial width */
    height: auto ;
    margin: 10px;
}

@media (max-width: 767px) {
    .experience-card-wrapper {
        width: auto; /* Initial width */
        height: auto ;
        margin: 10px;
        justify-content: center;
    }
}

.experience-card-wrapper .experience-card {
    width: 100% ;
    height: 100%;
    transition: background-color 0.5s, border 0.5s, box-shadow 1s;
    background-color: transparent;
    border-radius: 16px;
    overflow: hidden;
}

.experience-card-wrapper:hover .experience-card {
    background: rgba(2, 41, 79, 0.2);
    border: 1px solid rgba(54, 64, 73, 0.3);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.experience-card .experience-title {
    text-align: left;
    font-weight: bold;
    font-family: 'Overpass', sans-serif !important;
    color: #fff;
}

.experience-card .experience-subtitle {
    text-align: left;
    font-weight: bold;
    font-size: .90rem;
    opacity: 50%;
    font-family: 'Overpass', sans-serif !important;
    color: #94A6B8;
}

.experience-card .experience-company-title {
    color: #fff;
    font-weight: bold;
    font-family: 'Overpass', sans-serif !important;
}

.experience-company-title:hover {
    color: #367abf;
    text-decoration: none;
}

.company-arrow {
    transition: margin-left 0.3s, margin-bottom 0.3s;
    margin-left: 5px;
    margin-bottom: 5px;
  }

.experience-company-title:hover .company-arrow {
    margin-left: 20px;
    margin-bottom: 20px;
}

.experience-card .experience-description {
    text-align: left;
    font-family: 'Overpass', sans-serif;
    font-weight: 300;
    max-width:400px;
    color: #94A6B8;
}

.experience-card .experience-date {
    text-align: right;
    font-family: 'Overpass', sans-serif;
    font-weight: 600;
    color: #636e7a;
}
a:link { text-decoration: none; }