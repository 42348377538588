.App {
  text-align: center;
  background-color: #090E10;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
html, body {
  height: 100vh;
  width: 100vw;
  }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* styles.css */

.starter-page {
  text-align: center;
  margin-top: 100px; /* Adjust as needed */
}

.starter-page h1 {
  font-size: 3rem;
  color: #333; /* Adjust color as needed */
}

.starter-page h2 {
  font-size: 1.5rem;
  color: #666; /* Adjust color as needed */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
